import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/lab/Slider';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import 'typeface-roboto';


const dentists = [{
  id: 1,
  name: 'Tandarts A',
  address: {
    street: 'Straat 123',
    city: 'Den Haag',
  }
}, {
  id: 2,
  name: 'Tandarts B',
  address: {
    street: 'Weg 431',
    city: 'Den Haag',
  }
}, {
  id: 3,
  name: 'Tandarts C',
  address: {
    street: 'Zandpad 9',
    city: 'Den Haag',
  }
}];

function Header() {
  return (
    <div>
      <Typography variant="h1" component="h1" gutterBottom>
        Welke tandarts
      </Typography>
      <Link to="/">Start</Link>
      <ul>
        {dentists.map((dentist) => {
          return <li key={dentist.id}><Link to={'/profile/' + dentist.id}>{dentist.name}</Link></li>;
        })}
      </ul>
    </div>
  );
}

const factors = ['Parkeergelegenheid', 'Omgaan met angstige patiënten', 'informatie voor de behandeling', 'mordern uiterlijk van de praktijk', 'kindervriendelijkheid'];

function Factor(props) {
  return (
    <div>
      {props.labelText}:
      <Slider
        defaultValue={3}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        min={1}
        max={5}
        marks
      />
    </div>
  );
}

function Start() {
  const [id, setId] = useState();

  function submit(event) {
    setId(Math.floor(Math.random() * dentists.length) + 1 );
    event.preventDefault();
  }

  const dentist = id !== undefined && dentists.find((d) => {
    return d.id === id;
  });

  return (
    <div>
      {id === undefined && <form>
      <Typography variant="h2" gutterBottom>
        Waar bent u naar opzoek?
      </Typography>
      Tandarts: <Checkbox value="Tandarts" />
      Huisarts: <Checkbox value="Huisarts" />
      Fysiotherapeut: <Checkbox value="Fysiotherapeut" />
      Specialist: <Checkbox value="Specialist" />

      <Typography variant="h2" gutterBottom>
        Wat vindt u belangrijk?
      </Typography>
        Tandarts met affiniteit met kinderen
        <Slider
        defaultValue={5}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1}
        min={0}
        max={10}
        marks
        />
        <br />
        Binnen een straal aantal kilometer:
        <Slider
        defaultValue={10}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={2}
        min={0}
        max={20}
        marks
        />
        {factors
          .map((factor) => {
            return (
              <Factor labelText={factor} />
            );
          })
        }
        <Button onClick={submit}>vind mijn match</Button>
      </form>
      }

      {id !== undefined &&
        <div>
          <Typography variant="h2" gutterBottom>
            De volgende matches zijn voor u gevonden:
          </Typography>
          <div style={{
            display: 'inline-grid',
              gridTemplateRows: '12em',
              gridTemplateColumns: '12em 12em 12em',
              gridGap: '2em'
          }}>
            {[1, 2, 3]
              .map(() => {
                return (
                  <div style={{
                    display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid black',
                  }}>
                    <span>
                      <Link to={'/profile/' + id}>{dentist.name}</Link><br />
                      60% match
                      </span>
                  </div>
                );
              })
            }
          </div>
        </div>
      }
    </div>
  );
}

function Profile({ match }) {
  const dentist = dentists.find((d) => {
    return d.id === Number(match.params.id);
  });

  return (
    <div>
      <h2>Profiel {dentist.name}</h2>
      {dentist.name}<br />
      {dentist.address.street}<br />
      {dentist.address.city}
    </div>
  );
}

function App() {
  useEffect(() => {
    document.title = 'Welke tandarts';
  }, []);

  return (
    <Router>
      <div>
        <Header></Header>

        <Route path="/" exact component={Start} />
        <Route path="/profile/:id" component={Profile} />
      </div>
    </Router>
  );
}

export default App;
